import Chart from "chart.js";

function prepareLabels(labels: string[]) {
  const res = [];
  for (let i = 0; i < labels.length; i++) {
    // replace _ with space if any
    // change to title case using regex
    const preparedLabel = labels[i]
      .replace("_", " ")
      .replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    res.push(preparedLabel);
  }
  return res;
}

export default {
  createChart({
    data,
    type,
    label,
    div,
    options
  }: {
    data: Record<string, any>;
    type: string;
    label: string;
    div: Vue | Element | Vue[] | Element[];
    options: Record<string, any>;
  }) {
    const labels = prepareLabels(Object.keys(data));
    const values = Object.values(data);
    const ctx = div as HTMLCanvasElement;
    const backgroundColor = [];
    const borderColor = [];
    for (let i = 0; i < labels.length; i++) {
      const r = ((255 / labels.length) * i).toFixed(0);
      const g = (180 - (255 / labels.length) * i).toFixed(0);
      const b = 255;
      const backgroundRgb =
        "rgba(" +
        r.toString() +
        ", " +
        g.toString() +
        ", " +
        b.toString() +
        ", 0.5)";
      const borderRgb =
        "rgba(" +
        r.toString() +
        ", " +
        g.toString() +
        ", " +
        b.toString() +
        ", 1)";
      backgroundColor.push(backgroundRgb);
      borderColor.push(borderRgb);
    }
    return new Chart(ctx, {
      type: type,
      data: {
        labels: labels,
        datasets: [
          {
            label: label,
            data: values,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1
          }
        ]
      },
      options: options
    });
  }
};
